<template>
  <b-row>
    <b-col class="w-100">
        <b-row class="d-flex justify-content-between">
          <div> 
            <span class="pb-1 col-7">{{$t('creator.view_connections')}}</span>
          </div> 
          <div>
            <b-button
              class="btn-blue-brandme mr-1"
              @click="$router.push({name: 'pages-account-setting', params: { section: 'Social' } })"
            >
            <span class="text-network">{{ $t("buttonAddNetwork") }}</span>
            </b-button>
          </div>
        </b-row>
        <hr/>
      <b-card class="container-card-profile" @click="routerPush(network)" v-for="(network, index) in networks" :key="index">
        <div class="background-left-proposal" :style="getColorBackground(network.network)"/>
        <div class="d-flex">
          <b-avatar-group size="2.5rem">
            <b-avatar :src="utils.getImageNetworkColor(network.network)" class="avatar-network"></b-avatar>
            <b-avatar></b-avatar>
          </b-avatar-group>
          <div class="ml-1">
            <strong class="username-hover-network" @click="routerPush(network)" :style="getColor(network.network)">
              {{network.username}}
              <feather-icon icon="ChevronRightIcon"></feather-icon>
            </strong>
            <b-row class="mt-1">
              <b-col>
                <p class="text-muted">{{$t('campaigns.followers')}}</p>
                <p>{{(network.followers !== 0) ? utils.getFormat(network.followers) : '--'}}</p>
              </b-col>
              <b-col>
                <p class="text-muted">Engagement</p>
                <p>{{network.er !== null ? `${network.er} %` : '--'}}</p>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatarGroup,
  BAvatar,
} from 'bootstrap-vue';
import utils from '@/libs/utils';


export default {
  name: 'viewConnectionsCard',
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatarGroup,
    BAvatar,
  },
  props: {
    networks: {
      type: Array,
    }
  },
  data() {
    return {
      utils,
    }
  },
  methods: {
    getColorBackground(network) {
      return `background-color: ${utils.getColor(network)} !important`;
    },
    getColor(network) {
      return `color: ${utils.getColor(network)}`;
    },
    routerPush(network) {
      this.$router.push({name: 'detail_connection', params: {network: network.network, username: network.username}});
      this.$emit('update_parent');
    }
  }
}
</script>

<style> 
.avatar-network {
  padding: 0.2em;
  background-color: white;
}
.username-hover-network:hover {
  text-decoration: underline;
  cursor: pointer;
}
.background-left-proposal {
  width: 10px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0px;
}
.btn-blue-brandme {
  width: 140px;
  padding-left: 2px;
  padding-right: 2px;
  background: #0096fe !important;
  border: none;
}
.container-card-profile {
  cursor: pointer;
  overflow: hidden;
  margin-top: 20px;
}
</style>